<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>お知らせの登録</template>
              <template v-slot:body>
                <TopicForm ref="form" />
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item">
            <router-link class="btn btn-white" :to="{ name: listRoute }" >キャンセル</router-link>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-main" :handle-submit="register" button-text="登録する" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import nav from '@/mixins/nav/news';
import TopicForm from './Form.vue';

export default {
  name: 'Home',
  data: function() {
    return {
      pageName: 'お知らせ',
    };
  },
  computed: {
    ...mapGetters({
      infor: 'auth/infor',
      newSubdomain: 'common/subdomain',
      isGmoOrOfficeLoginStore: 'common/isGmoOrOfficeLoginStore',
      isGmoOrOfficeLoginStoreAndStore: 'common/isGmoOrOfficeLoginStoreAndStore',
    }),
    subdomain() {
      if (this.$permission.isStore()) {
        return this.infor.event?.subdomain || null;
      } else {
        return (this.isGmoOrOfficeLoginStore ? this.newSubdomain : this.$route.params?.subdomain) || null;
      }
    },
    pageNavs() {
      if (this.isGmoOrOfficeLoginStoreAndStore) {
        return this.storePageNavs;
      } else {
        return this.officePageNavs;
      }
    },
    listRoute() {
      if (this.isGmoOrOfficeLoginStoreAndStore) {
        return 'ShopNews';
      } else {
        return 'News';
      }
    },
  },
  components: {
    TopicForm,
  },
  mixins: [nav],
  methods: {
    register: async function() {
      const payload = await this.$refs.form.validate();
      if (payload) {
        const result = await this.$store.dispatch('news/createNews', {
          ...payload,
          subdomain: this.subdomain,
        });
        if (result) {
          await this.$router.push({ name: this.listRoute });
          this.$message.created('newTopic');
        }
      }
    },
  },
};
</script>
